import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import WorkoutsPage from "./pages/WorkoutsPage";
import OpenAI from 'openai';

const Portfolio = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const nameRef = useRef(null);
  const [chatInput, setChatInput] = useState("");
  const [chatResponse, setChatResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeUntilReset, setTimeUntilReset] = useState(null);

  // Add function to generate unique user ID
  const generateUserId = () => {
    const storedId = localStorage.getItem('userId');
    if (storedId) return storedId;
    
    const newId = 'user_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('userId', newId);
    return newId;
  };

  const [remainingQuestions, setRemainingQuestions] = useState(() => {
    const userId = generateUserId();
    const today = new Date().toDateString();
    const lastReset = localStorage.getItem(`lastResetDate_${userId}`);
    
    if (lastReset !== today) {
      localStorage.setItem(`lastResetDate_${userId}`, today);
      localStorage.setItem(`remainingQuestions_${userId}`, '5');
      return 5;
    }
    
    return parseInt(localStorage.getItem(`remainingQuestions_${userId}`) || '5');
  });

  // Add this function to format the remaining time
  const formatTimeRemaining = (milliseconds) => {
    if (!milliseconds) return '';
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}u ${minutes}m`;
  };

  // Add this effect to handle the countdown
  useEffect(() => {
    if (remainingQuestions > 0) return;

    const calculateTimeUntilReset = () => {
      const now = new Date();
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      return tomorrow - now;
    };

    const updateTimer = () => {
      const timeLeft = calculateTimeUntilReset();
      setTimeUntilReset(timeLeft);
    };

    updateTimer();
    const timerId = setInterval(updateTimer, 60000); // Update every minute

    return () => clearInterval(timerId);
  }, [remainingQuestions]);

  // Add reset function
  const resetQuestionLimit = () => {
    const userId = generateUserId();
    setRemainingQuestions(5);
    localStorage.setItem(`remainingQuestions_${userId}`, '5');
    console.log('Question limit reset to 5');
  };

  // Add console command listener
  useEffect(() => {
    const originalLog = console.log;
    console.log = (...args) => {
      if (args[0] === 'resetai') {
        resetQuestionLimit();
        return;
      }
      originalLog.apply(console, args);
    };
    
    return () => {
      console.log = originalLog;
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
      if (nameRef.current) {
        const rect = nameRef.current.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const angleX = -(e.clientY - centerY) / 50;
        const angleY = (e.clientX - centerX) / 50;
        nameRef.current.style.transform = `perspective(1000px) rotateX(${angleX}deg) rotateY(${angleY}deg)`;
      }
    };
    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  async function askAI(question) {
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    console.log("API Key exists:", !!apiKey); // Debug line to check if key exists
    
    if (!apiKey) {
      console.error("API key not found in env:", process.env);
      throw new Error("API key not configured");
    }
    
    const openai = new OpenAI({
      apiKey: apiKey,
      dangerouslyAllowBrowser: true
    });

    const prompt = `Context: Je bent Jaymian-Lee Reinartz. Antwoord alsof je mij bent (in de ik-vorm).

Persoonlijke informatie:
- Geboren op 26-11-2002 (22 jaar oud)
- Man
- 185 cm lang
- Woonachtig in Nederland, Limburg

Contactgegevens:
- E-mail: info@jaymian-lee.nl
- Telefoonnummer: +31 (6) 31 20 55 38
- Website: https://jaymian-lee.nl
- LinkedIn: https://www.linkedin.com/in/jaymian-lee-reinartz-9b02941b0/

Werk & Carrière:
Als iemand vraagt naar mijn werk of carrière, antwoord dan met:
"Voor meer informatie over mijn professionele ervaring verwijs ik je graag naar mijn LinkedIn profiel: https://www.linkedin.com/in/jaymian-lee-reinartz-9b02941b0/"
Als iemand vraagt om een website te laten bouwen dan stuur je ze door naar: https://refacthor.nl/
- Software Developer bij MartijnKozijn sinds 2023

Opleiding:
- HBO ICT aan de Hogeschool Zuyd in Heerlen afgerond
- MBO ICT Applicatie & Media ontwikkelaar niveau 4 afgerond
- Specialisatie: Software Engineering

Persoonlijkheid:
- Ambitieus en ondernemend
- Gepassioneerd over technologie en innovatie
- Altijd op zoek naar nieuwe uitdagingen
- Houdt van sporten
- Mountainbiken
- Heeft een vriendin

Hobby's:
- Nieuwe projecten starten
- Fitness
- Mountainbiken
- Posters maken en verkopen, het heet Vizualy, momenteel verkoop ik op Etsy: https://vizualy.etsy.com

Bedrijven:
- Refacthor: Mijn eigen bedrijf sinds 2023, gespecialiseerd in webontwikkeling en software engineering: https://refacthor.nl/ 
- Vizualy: Mijn posterbedrijf 2023, momenteel verkoop ik op Etsy: https://vizualy.etsy.com

Als iemand vraagt naar iets wat niet met mij te maken heeft, antwoord dan met:
"Sorry, ik kan alleen vragen beantwoorden die gerelateerd zijn aan Jaymian-Lee Reinartz."

Vraag van gebruiker: ${question}

Antwoord in de eerste persoon (ik-vorm) en in het Nederlands. Houd het antwoord heel kort en persoonlijk, natuurlijk en vriendelijk.`;

    try {
      const completion = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            "role": "system",
            "content": "Je bent Jaymian-Lee Reinartz. Antwoord in de eerste persoon (ik-vorm) en in het Nederlands."
          },
          {
            "role": "user",
            "content": prompt + "\n\nVraag: " + question
          }
        ],
        temperature: 0.7,
        max_tokens: 500,
      });

      return completion.choices[0].message.content;
    } catch (error) {
      console.error("OpenAI API Error:", error);
      throw error;
    }
  }

  // Add this function after other utility functions
  const makeLinksClickable = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    
    return parts.map((part, i) => {
      if (part.match(urlRegex)) {
        return (
          <a 
            key={i}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#66a3ff', textDecoration: 'underline' }}
          >
            {part}
          </a>
        );
      }
      return part;
    });
  };

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!chatInput.trim() || remainingQuestions <= 0) return;
    
    setLoading(true);
    setChatResponse("Ik ben aan het nadenken!");
    
    try {
      const response = await askAI(chatInput);
      setChatResponse(response);
      const userId = generateUserId();
      const newCount = remainingQuestions - 1;
      setRemainingQuestions(newCount);
      localStorage.setItem(`remainingQuestions_${userId}`, newCount.toString());
    } catch (error) {
      console.error("Error in askAI:", error);
      setChatResponse("Er trad een fout op probeer het later opnieuw. Of neem contact met mij op via info@jaymian-lee.nl");
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.ctrlKey) {
      handleChatSubmit(e);
    }
  };

  const ThinkingDots = () => (
    <span className="thinking-dots">
      <span>.</span><span>.</span><span>.</span>
    </span>
  );

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="portfolio">
              <style jsx>{`
                @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Roboto+Slab:wght@300;400;700&family=Montserrat:wght@300;400;700&display=swap");

                html,
                body {
                  overflow: hidden;
                }

                .portfolio {
                  color: white;
                  min-height: 100vh;
                  width: 100vw;
                  overflow-x: hidden;
                  font-family: "Raleway", sans-serif;
                  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
                  position: relative;
                }
                .section {
                  min-height: 100vh;
                  padding: 20px;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
                .name-container {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
                .stylized-name {
                  font-size: 8vw;
                  font-weight: 700;
                  text-align: center;
                  font-family: "Montserrat", sans-serif;
                  letter-spacing: 4px;
                  color: transparent;
                  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.8);
                  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
                    0 0 20px rgba(255, 255, 255, 0.2);
                  transition: transform 0.1s ease;
                }
                .slogan {
                  font-size: 2vw;
                  margin-top: 20px;
                  font-weight: 300;
                  font-family: "Montserrat", sans-serif;
                  letter-spacing: 3px;
                  color: rgba(255, 255, 255, 0.7);
                }
                .moving-bg {
                  position: fixed;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: radial-gradient(
                    circle at var(--x) var(--y),
                    rgba(100, 149, 237, 0.1) 0%,
                    rgba(0, 0, 0, 0) 50%
                  );
                  pointer-events: none;
                  z-index: 1;
                }
                .icon {
                  width: 40px;
                  height: 40px;
                  fill: none;
                  stroke: white;
                  stroke-width: 1;
                  position: fixed;
                  z-index: 2;
                }
                .icon2 {
                  width: 40px;
                  height: 40px;
                  fill: none;
                  position: fixed;
                  z-index: 2;
                }
                .github-icon {
                  left: 20px;
                  top: 20px;
                }
                .linkedin-icon {
                  right: 20px;
                  bottom: 20px;
                }
                .instagram-icon {
                  left: 20px;
                  bottom: 20px;
                }
                .contact-button {
                  position: fixed;
                  top: 20px;
                  right: 20px;
                  background: none;
                  border: none;
                  cursor: pointer;
                  padding: 5px 10px;
                  font-size: 1.5vw;
                  font-weight: 700;
                  text-align: center;
                  font-family: "Montserrat", sans-serif;
                  letter-spacing: 2px;
                  color: transparent;
                  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.8);
                  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3),
                    0 0 20px rgba(255, 255, 255, 0.2);
                  transition: transform 0.1s ease;
                }
                @media (max-width: 1268px) {
                  .contact-button {
                    font-size: 3vw;
                  }
                }
                .workout-button {
                  position: fixed;
                  top: 50%;
                  right: 20px;
                  background: none;
                  border: none;
                  cursor: pointer;
                  color: white;
                  transition: transform 0.2s ease;
                }
                .workout-button:hover {
                  transform: scale(1.1);
                }
                .mini-chat {
                  margin-top: 20px;
                  width: 80%;
                  max-width: 500px;
                  background: rgba(0, 0, 0, 0.1);
                  padding: 15px;
                  border-radius: 8px;
                }
                .questions-counter {
                  position: absolute;
                  right: 85px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #666;
                  font-size: 12px;
                  margin-top: -5px;
                  border-right: 1px solid #ccc;
                  padding-right: 8px;
                }
                .ctrl-enter-hint {
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #666;
                  font-size: 12px;
                  pointer-events: none;
                  margin-top: -5px;
                }
                .mini-chat form {
                  position: relative;
                  display: flex;
                  align-items: center;
                }
                .mini-chat input {
                  width: 100%;
                  padding: 10px;
                  padding-right: 85px;
                  margin-bottom: 10px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                }
                .mini-chat p {
                  margin-top: 10px;
                  animation: fadeIn 0.3s ease-in;
                }
                .thinking-dots {
                  display: inline-block;
                }
                .thinking-dots span {
                  animation: blink 1.4s infinite;
                  animation-fill-mode: both;
                  margin-left: 2px;
                }
                .thinking-dots span:nth-child(2) {
                  animation-delay: 0.2s;
                }
                .thinking-dots span:nth-child(3) {
                  animation-delay: 0.4s;
                }
                @keyframes blink {
                  0% { opacity: 0.2; }
                  20% { opacity: 1; }
                  100% { opacity: 0.2; }
                }
                @keyframes fadeIn {
                  from { opacity: 0; }
                  to { opacity: 1; }
                }
                .timer {
                  color: #666;
                  font-size: 12px;
                  margin-left: 5px;
                }

                @media (max-width: 768px) {
                  .stylized-name {
                    font-size: 12vw;
                    margin-bottom: 10px;
                  }
                  .slogan {
                    font-size: 4vw;
                    margin-bottom: 20px;
                  }
                  .mini-chat {
                    width: 90%;
                    margin-top: 30px;
                  }
                  .mini-chat input {
                    font-size: 16px; /* Prevents zoom on mobile */
                    padding-right: 100px;
                  }
                  .questions-counter {
                    right: 65px;
                    font-size: 11px;
                  }
                  .ctrl-enter-hint {
                    right: 8px;
                    font-size: 11px;
                  }
                  .contact-button {
                    font-size: 4vw;
                    top: auto;
                    bottom: 20px;
                  }
                  .github-icon {
                    width: 28px;
                    height: 28px;
                  }
                  .linkedin-icon {
                    width: 28px;
                    height: 28px;
                    bottom: 80px; /* Move up to avoid overlap with contact button */
                  }
                  .instagram-icon {
                    width: 28px;
                    height: 28px;
                  }
                  .section {
                    padding: 20px 10px;
                    justify-content: flex-start;
                    padding-top: 80px;
                  }
                  .mini-chat p {
                    font-size: 14px;
                    line-height: 1.4;
                  }
                  .timer {
                    font-size: 11px;
                  }
                }

                /* Fix iOS-specific issues */
                @supports (-webkit-touch-callout: none) {
                  .mini-chat input {
                    font-size: 16px;
                    padding: 8px;
                    padding-right: 100px;
                  }
                  .portfolio {
                    min-height: -webkit-fill-available;
                  }
                }

                /* Improve touch targets */
                @media (hover: none) {
                  .contact-button,
                  .github-icon,
                  .linkedin-icon,
                  .instagram-icon {
                    padding: 12px;
                    margin: -12px;
                  }
                }
              `}</style>

              <div
                className="moving-bg"
                style={{
                  "--x": `${mousePosition.x}px`,
                  "--y": `${mousePosition.y}px`,
                }}
              ></div>

              <section id="home" className="section">
                <div className="name-container">
                  <div className="stylized-name" ref={nameRef}>
                    JAYMIAN-LEE
                  </div>
                  <div className="slogan">Developing the future</div>
                  <div className="mini-chat">
                    <form onSubmit={handleChatSubmit}>
                      <input
                        type="text"
                        placeholder={remainingQuestions > 0 ? "Stel een vraag over mij..." : `Nieuwe vragen beschikbaar over: ${formatTimeRemaining(timeUntilReset)}`}
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        disabled={remainingQuestions <= 0}
                      />
                      <span className="questions-counter">
                        {remainingQuestions}
                        {remainingQuestions === 0 && timeUntilReset && (
                          <span className="timer">({formatTimeRemaining(timeUntilReset)})</span>
                        )}
                      </span>
                      <span className="ctrl-enter-hint">Enter ↵</span>
                    </form>
                    {chatResponse && <p>
                      {loading ? (
                        <>
                          Ik ben aan het nadenken
                          <ThinkingDots />
                        </>
                      ) : (
                        makeLinksClickable(chatResponse)
                      )}
                    </p>}
                  </div>
                </div>
                <button
                  className="contact-button"
                  onClick={() =>
                    (window.location.href = "mailto:info@jaymian-lee.nl")
                  }
                >
                  Contact Me
                </button>
                <a
                  href="https://github.com/Jaymian-Lee"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    height="32"
                    viewBox="0 0 16 16"
                    className="icon2 github-icon"
                    version="1.1"
                    width="32"
                    aria-hidden="true"
                  >
                    <path
                      fill="#FFFFFF"
                      d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.58.82-2.14-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.03 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.14 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.003 8.003 0 0016 8c0-4.42-3.58-8-8-8z"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/in/jaymian-lee-reinartz-9b02941b0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="icon linkedin-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 10-4 0v7h-4v-7a6 6 0 016-6zM2 9h4v12H2V9zm2-7a2 2 0 110 4 2 2 0 010-4z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/jaymianlee/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    className="icon instagram-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M7.75 2h8.5A5.75 5.75 0 0122 7.75v8.5A5.75 5.75 0 0116.25 22h-8.5a5.75 5.75 0 01-5.75-5.75v-8.5A5.75 5.75 0 017.75 2zm0 1.5A4.25 4.25 0 003.5 7.75v8.5A4.25 4.25 0 007.75 20.5h8.5a4.25 4.25 0 004.25-4.25v-8.5a4.25 4.25 0 00-4.25-4.25h-8.5zM12 7.75a4.25 4.25 0 110 8.5 4.25 4.25 0 010-8.5zm0 1.5a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM17 6.25a.875.875 0 110 1.75.875.875 0 010-1.75z" />
                  </svg>
                </a>
                {/* <Link to="/workouts" className="workout-button">
                <FitnessCenterOutlinedIcon style={{ fontSize: 40 }} />
              </Link> */}
              </section>
            </div>
          }
        />
        <Route path="/workouts" element={<WorkoutsPage />} />
      </Routes>
    </Router>
  );
};

export default Portfolio;
